import * as React from "react";
import { useTranslation } from "react-i18next";
import Select from "components/Select";
import { useParams } from "react-router-dom";
import { MachineSnapshotStatus, MachineSnapshotType } from "gql/generated";
import { Tooltip } from "@mui/material";
import { toasti18n } from "utils/toast";
import {
  useMachineSnapshot,
  SNAPSHOT_TYPE_TO_TRANSLATION,
} from "./useMachineSnapshot";
import { PlaceHolderScreen } from "./PlaceHolderScreen";
import { getStatusIndicator } from "./getStatusIndicator";
import { DateHeaders, timeHeader } from "./DateHeaders";

const options = [
  { value: MachineSnapshotType.Inventory, label: "inventory" },
  { value: MachineSnapshotType.Module, label: "module" },
];

export function Snapshots() {
  const { t } = useTranslation("machine", { keyPrefix: "snapshots" });

  const [selected, setSelected] = React.useState(MachineSnapshotType.Inventory);

  const { data, isLoading, isError } = useMachineSnapshot(
    {
      filter: {
        machineId: useParams().machineId,
        type: selected,
      },
    },
    {
      structuralSharing: false,
      isDataEqual: () => false,
      useErrorBoundary: false,
      onError: (error: Error) => {
        toasti18n.error(error);
      },
    }
  );

  const scrollContainerRef = React.useRef();
  React.useLayoutEffect(() => {
    const element = scrollContainerRef.current as HTMLElement;
    element.scrollTo({
      left: element.scrollWidth,
    });
  }, []);
  React.useLayoutEffect(() => {
    const element = scrollContainerRef.current as HTMLElement;
    element.scrollTo({
      top: 0,
    });
  }, [selected]);

  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);

  const firstFutureIndex = data
    ? data[0].snapshots.findIndex((snapshot) => snapshot.isFuture)
    : undefined;
  const pastSegmentDimensions = data
    ? {
        width: firstFutureIndex * 40,
        left: "var(--col-size)",
      }
    : undefined;
  const futureSegmentDimensions = data
    ? {
        width: (37 - firstFutureIndex) * 40,
        left: `calc(var(--col-size) + ${pastSegmentDimensions.width}px)`,
      }
    : undefined;

  return (
    <div className="min-h-0 flex-1 overflow-auto [scrollbar-gutter:stable] -mx-[20px] px-4 md:-mx-[35px] -my-[20px] md:px-[35px] py-[20px] basis-0 flex flex-col">
      <div className="font-kanit ">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-h5 leading-6">{t("snapshots")}</h1>
            <p className="text-caption leading-4 text-on-surface-medium hidden md:block pr-3">
              {t("description")}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6 md:mt-7 grow basis-0 md:min-h-0 [--col-size:106px] md:[--col-size:138px]">
        <div
          className="md:max-h-full overflow-auto rounded-lg border-[1px] border-outline bg-white md:overscroll-contain"
          ref={scrollContainerRef}
        >
          <div className="bg-white min-w-min">
            <div className="flex border-b-[1px] border-on-surface-medium sticky top-0 bg-white z-10">
              <div className="w-[var(--col-size)] box-border px-[6.5px] flex-shrink-0 flex items-center justify-center py-[10px] sticky left-0 bg-white">
                <Select
                  label=""
                  id="type"
                  value={selected}
                  onChange={(e) => {
                    setSelected(e.target.value);
                  }}
                  options={options.map((option) => ({
                    ...option,
                    label: t(option.label),
                  }))}
                  size="small"
                />
              </div>

              <div className="flex flex-col">
                <DateHeaders machineSnapshot={data} />
                {timeHeader}
              </div>
            </div>

            <div
              className="animate-fade-in relative isolate"
              key={selected + isLoading}
            >
              <div className="flex">
                <div className="w-[var(--col-size)] h-[8px] border-r border-[#BDBDBD] box-border left-0 sticky bg-white z-10"></div>
              </div>

              {isLoading ? (
                <PlaceHolderScreen type={selected} status={"loading"} />
              ) : null}

              {isError && !data ? (
                <PlaceHolderScreen type={selected} status={"error"} />
              ) : null}

              {data ? (
                <div>
                  {data.map((value, index) => (
                    <div
                      key={value.type}
                      className="group"
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <div className="flex group-hover:bg-[#F1F1F6] relative">
                        <div className="w-[var(--col-size)] border-r border-[#BDBDBD] box-border flex items-center justify-end gap-2 text-caption text-primary-600 group-hover:font-medium sticky left-0 bg-white group-hover:bg-[#F1F1F6] z-10 ">
                          <span className="whitespace-nowrap overflow-hidden text-ellipsis pl-[15px]">
                            {t(SNAPSHOT_TYPE_TO_TRANSLATION[value.type], {
                              defaultValue: value.type,
                            })}
                          </span>

                          <div className="h-px w-[15px] bg-[#E0E0E0] flex-shrink-0" />
                        </div>

                        <div
                          style={pastSegmentDimensions}
                          className="self-center h-[1px] absolute bg-[#E0E0E0]"
                        />

                        <div
                          style={futureSegmentDimensions}
                          className="self-center h-[1px] absolute bg-[#F2F2F2] group-hover:bg-[#E0E0E0]"
                        />

                        {value.snapshots.map((snapshot) => {
                          if (hoveredRow !== index) {
                            return (
                              <div
                                key={snapshot.timestamp}
                                className="w-[40px] h-[30px] flex items-center justify-center relative"
                              >
                                {getStatusIndicator({ snapshot })}
                              </div>
                            );
                          }

                          return (
                            <div
                              key={snapshot.timestamp}
                              className="w-[40px] h-[30px] flex items-center justify-center relative"
                            >
                              <Tooltip
                                placement="top-start"
                                arrow={true}
                                slotProps={{
                                  popper: {
                                    className: "pointer-events-none",
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [-12, -10],
                                        },
                                      },
                                    ],
                                  },
                                }}
                                title={
                                  snapshot.consecutiveIndexes === 1 ||
                                  snapshot.status ===
                                    MachineSnapshotStatus.NoData ? null : (
                                    <div className="font-kanit text-overline tracking-[0.4px] text-primary-50 pointer-events-none">
                                      {t("ongoing", {
                                        hours: `${(snapshot.consecutiveIndexes - 1) * 2}`,
                                      })}
                                    </div>
                                  )
                                }
                              >
                                <div>{getStatusIndicator({ snapshot })}</div>
                              </Tooltip>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div className="w-[1px] absolute top-0 bottom-0 left-[calc(var(--col-size)+19.5px)] bg-[#BDBDBD]" />
              <div className="w-[1px] absolute top-0 bottom-0 left-[calc(var(--col-size)+calc(calc(12*40px)+19.5px))] bg-[#BDBDBD]" />
              <div className="w-[1px] absolute top-0 bottom-0 left-[calc(var(--col-size)+calc(calc(24*40px)+19.5px))] bg-[#BDBDBD]" />
              <div className="w-[1px] absolute top-0 bottom-0 left-[calc(var(--col-size)+calc(calc(36*40px)+19.5px))] bg-[#BDBDBD]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

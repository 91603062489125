import AcceptInventory from "./AcceptInventory";
import AddWarehouse from "./AddWarehouse";
import AlertIcon from "./AlertIcon";
import Android from "./Android";
import Aperture from "./Aperture";
import ArrowTop from "./ArrowTop";
import ArrowBottom from "./ArrowBottom";
import Avatar from "./Avatar";
import AlertRefresh from "./AlertRefresh";
import AlertTriangle from "./AlertTriangle";
import Cart from "./Cart";
import Calendar from "./Calendar";
import ChangeZone from "./ChangeZone";
import Chat from "./Chat";
import Check from "./Check";
import CheckedClipboard from "./CheckedClipboard";
import Chevron from "./Chevron";
import ChevronsRight from "./ChevronsRight";
import ChevronThick from "./ChevronThick";
import CircleMinus from "./CircleMinus";
import CirclePlus from "./CirclePlus";
import Clipboard from "./Clipboard";
import Clock from "./Clock";
import Close from "./Close";
import CloseFilled from "./CloseFilled";
import Coffee from "./Coffee";
import Coin from "./Coin";
import Copy from "./Copy";
import CopySquare from "./CopySquare";
import CreditCard from "./CreditCard";
import Critical from "./Critical";
import CoffeeCup from "./CoffeeCup";
import DashboardDial from "./DashboardDial";
import Delivery from "./Delivery";
import Download from "./Download";
import Diamond from "./Diamond";
import DownloadDocument from "./DownloadDocument";
import DownloadTray from "./DownloadTray";
import Drink from "./Drink";
import DropDownChevron from "./DropDownChevron";
import Edit from "./Edit";
import Enroute from "./Enroute";
import Error from "./Error";
import Ellipsis from "./Ellipsis";
import FolderPhoto from "./FolderPhoto";
import Filter from "./Filter";
import Filter2 from "./Filter2";
import Forecast from "./Forecast";
import Google from "./Google";
import Grid from "./Grid";
import HardwareVersion from "./HardwareVersion";
import Health from "./Health";
import Info from "./Info";
import Inventory from "./Inventory";
import InventoryReport from "./InventoryReport";
import Kitchen from "./Kitchen";
import LightBulb from "./LightBulb";
import Lightning from "./Lightning";
import List from "./List";
import Location from "./Location";
import LocationAdd from "./LocationAdd";
import Machine from "./Machine";
import MachineAdd from "./MachineAdd";
import MachineCash from "./MachineCash";
import MachineRegistration from "./MachineRegistration";
import Maintenance from "./Maintenance";
import Map from "./Map";
import MoveInventory from "./MoveInventory";
import Notification from "./Notification";
import Organisation from "./Organisation";
import PaginationChevron from "./PaginationChevron";
import PaginationDoubleChevron from "./PaginationDoubleChevron";
import Pause from "./Pause";
import Pencil from "./Pencil";
import PencilCreate from "./PencilCreate";
import Play from "./Play";
import Plus from "./Plus";
import Printer from "./Printer";
import PriorityHigh from "./PriorityHigh";
import PriorityLow from "./PriorityLow";
import PriorityLowest from "./PriorityLowest";
import PriorityMajor from "./PriorityMajor";
import PriorityMedium from "./PriorityMedium";
import PriorityOverdue from "./PriorityOverdue";
import PriorityUrgent from "./PriorityUrgent";
import PriorityVeryHigh from "./PriorityVeryHigh";
import Question from "./Question";
import QuestionCircle from "./QuestionCircle";
import ReactionsNeutral from "./ReactionsNeutral";
import ReactionsSad from "./ReactionsSad";
import ReactionsSmiled from "./ReactionsSmiled";
import RefillSheet from "./RefillSheet";
import Reply from "./Reply";
import Refresh from "./Refresh";
import RotateCW from "./RotateCW";
import RefreshAlert from "./RefreshAlert";
import Reverse from "./Reverse";
import RightCircularChevron from "./RightCircularChevron";
import Service from "./Service";
import Search from "./Search";
import Settings from "./Settings";
import Sliders from "./Sliders";
import SortChevrons from "./SortChevrons";
import Speedometer from "./Speedometer";
import StaffAtMachine from "./StaffAtMachine";
import StaffNotAtMachine from "./StaffNotAtMachine";
import StandbyMachine from "./StandbyMachine";
import StatusEye from "./StatusEye";
import Swap from "./Swap";
import SmallTruck from "./SmallTruck";
import Table from "./Table";
import TableHeaderSortChevron from "./TableHeaderSortChevron";
import TableHeaderSortChevronAsc from "./TableHeaderSortChevronAsc";
import Team from "./Team";
import ThumbsUp from "./ThumbsUp";
import Time from "./Time";
import Timeline from "./Timeline";
import Transfer from "./Transfer";
import Trash from "./Trash";
import TrashInventoryV2 from "./TrashInventoryV2";
import Truck from "./Truck";
import Upload from "./Upload";
import User from "./User";
import Vendii from "./Vendii";
import VendiiSymbol from "./VendiiSymbol";
import VendingMachine from "./VendingMachine";
import VMWater from "./VMWater";
import Wagon from "./Wagon";
import Warehouse from "./Warehouse";
import Warehouse051 from "./Warehouse051";
import Water from "./Water";
import loader from "./loader";
import Cash from "./Cash";
import ChevronRight from "./ChevronRight";
import DoubleChevronLeft from "./DoubleChevronLeft";
import CircleCheck from "./CircleCheck";
import FolderOpen from "./FolderOpen";
import Box from "./Box";
import File from "./File";
import Inbox from "./Inbox";
import Share from "./Share";
import Snack from "./Snack";
import Password from "./Password";
import BottomBoard from "./BottomBoard";
import FrontBoard from "./FrontBoard";
import TopBoard from "./TopBoard";
import Star from "./Star";
import AlertOutline from "./AlertOutline";
import EyeNo from "./EyeNo";
import MoneyAll2 from "./MoneyAll2";
import Flame from "./Flame";
import MaintenanceTest from "./MaintenanceTest";

export default {
  MaintenanceTest,
  Flame,
  MoneyAll2,
  AcceptInventory,
  AddWarehouse,
  AlertIcon,
  Android,
  Aperture,
  ArrowTop,
  ArrowBottom,
  Avatar,
  AlertRefresh,
  AlertTriangle,
  Cart,
  Calendar,
  ChangeZone,
  Chat,
  Check,
  CheckedClipboard,
  Chevron,
  ChevronsRight,
  ChevronThick,
  CircleMinus,
  CirclePlus,
  Clock,
  Clipboard,
  Close,
  CloseFilled,
  Coffee,
  Coin,
  Copy,
  CopySquare,
  CreditCard,
  Critical,
  CoffeeCup,
  DashboardDial,
  Delivery,
  Diamond,
  Download,
  DownloadDocument,
  DownloadTray,
  Drink,
  DropDownChevron,
  Edit,
  Enroute,
  Error,
  Ellipsis,
  FolderPhoto,
  Forecast,
  Filter,
  Filter2,
  Google,
  Grid,
  HardwareVersion,
  Health,
  Info,
  Inventory,
  InventoryReport,
  Kitchen,
  LightBulb,
  Lightning,
  List,
  Location,
  LocationAdd,
  Machine,
  MachineAdd,
  MachineCash,
  MachineRegistration,
  Maintenance,
  Map,
  MoveInventory,
  Notification,
  Organisation,
  PaginationChevron,
  PaginationDoubleChevron,
  Pause,
  Pencil,
  PencilCreate,
  Play,
  Plus,
  Printer,
  PriorityHigh,
  PriorityLow,
  PriorityLowest,
  PriorityMajor,
  PriorityMedium,
  PriorityOverdue,
  PriorityUrgent,
  PriorityVeryHigh,
  Question,
  QuestionCircle,
  ReactionsNeutral,
  ReactionsSad,
  ReactionsSmiled,
  RefillSheet,
  Reply,
  Refresh,
  RefreshAlert,
  Reverse,
  RightCircularChevron,
  RotateCW,
  Service,
  Search,
  Settings,
  Sliders,
  SortChevrons,
  Speedometer,
  StaffAtMachine,
  StaffNotAtMachine,
  StandbyMachine,
  StatusEye,
  Swap,
  SmallTruck,
  Table,
  TableHeaderSortChevron,
  TableHeaderSortChevronAsc,
  Team,
  ThumbsUp,
  Time,
  Timeline,
  Transfer,
  Trash,
  TrashInventoryV2,
  Truck,
  Upload,
  User,
  Vendii,
  VendiiSymbol,
  VendingMachine,
  VMWater,
  Wagon,
  Warehouse,
  Warehouse051,
  Water,
  loader,
  Cash,
  ChevronRight,
  DoubleChevronLeft,
  CircleCheck,
  FolderOpen,
  Box,
  File,
  Inbox,
  Share,
  Snack,
  Password,
  BottomBoard,
  FrontBoard,
  TopBoard,
  Star,
  AlertOutline,
  EyeNo,
};

import * as React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { GetRefillZonesListQuery } from "gql/generated";


export function useColumnConfig() {
  const { t } = useTranslation("location", { keyPrefix: "refillZonesList.table.column"});
  const columnHelper = createColumnHelper<GetRefillZonesListQuery["refillZones2"]["edges"][number]["node"]>();
  return [
    columnHelper.accessor("friendlyId", {
      header: () => <>{t("refill_zone_id")}</>,
      cell: (info) => <>{info.getValue()}</>,
      minSize: 30,
      meta: {
        autoColumnWidth: true
      }
    }),
    columnHelper.accessor("name", {
      header: () => <>{t("refill_zone_name")}</>,
      cell: (info) => <>{info.getValue()}</>,
      enableColumnFilter: true,
      minSize: 30,
      meta: {
        autoColumnWidth: true,
        columnFilter: {
          location: "primary",
          type: "text",
          placeholder: t("refill_zone_name"),
          label: t("refill_zone_name"),
        },
      },
    }),
    ];
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import { MachineSnapshotType } from "gql/generated";
import { Skeleton } from "@mui/material";
import { SNAPSHOT_TYPE_TO_TRANSLATION } from "./useMachineSnapshot";

export function PlaceHolderScreen({
  type,
  status,
}: {
  type: MachineSnapshotType;
  status: "loading" | "error";
}) {
  const { t } = useTranslation("machine", { keyPrefix: "snapshots" });
  const types =
    type === MachineSnapshotType.Inventory ? INVENTORY_TYPES : MODULE_TYPES;
  return (
    <div>
      {types.map((value) => (
        <div key={value} className="group">
          <div className="flex group-hover:bg-[#F1F1F6] relative">
            <div className="w-[var(--col-size)] border-r border-[#BDBDBD] box-border flex items-center justify-end gap-2 text-caption text-primary-600 group-hover:font-medium sticky left-0 bg-white group-hover:bg-[#F1F1F6] z-10 ">
              <span className="whitespace-nowrap overflow-hidden text-ellipsis pl-[15px]">
                {SNAPSHOT_TYPE_TO_TRANSLATION[value]
                  ? t(SNAPSHOT_TYPE_TO_TRANSLATION[value])
                  : value}
              </span>

              <div className="h-[1px] w-[15px] bg-[#E0E0E0] flex-shrink-0" />
            </div>

            <div className="self-center h-[1px] left-[var(--col-size)] w-[calc(40px*37)] absolute bg-[#E0E0E0]" />
            {status === "loading" ? loadingRow : errorRow}
          </div>
        </div>
      ))}
    </div>
  );
}

const errorCell = (
  <div className="w-[40px] h-[30px] flex items-center justify-center relative">
    <div className="w-[10px] h-[10px] border box-border border-[#E0E0E0] rounded-full bg-white group-hover:border-[#E0E0E0] group-hover:bg-[#F1F1F6]" />
  </div>
);

const errorRow = new Array(37).fill(errorCell);

const loadingCell = (
  <div className="w-[40px] h-[30px] flex items-center justify-center relative overflow-hidden">
    <Skeleton width={10} height={10} className="rounded-full scale-100" />
  </div>
);

const loadingRow = new Array(37).fill(loadingCell);


const MODULE_TYPES = [
  "machine_error",
  "ice",
  "coffee",
  "out_of_service",
  "offline",
];

const INVENTORY_TYPES = [
  "plastic_cup",
  "paper_cup",
  "espresso_cup",
  "P1",
  "P2",
  "P3",
  "P4",
  "P5",
  "P6",
  "P7",
  "P8",
  "P9",
  "P10",
  "P11",
  // ONLY SHOW 12 INVENTORY ITEMS BECAUSE DON"T WANT TO RENDER A LOT OF SKELETON CIRCLES
  // "coffee_material",
  // "S1",
  // "S2",
  // "S3",
  // "S4",
  // "S5",
  // "S6",
  // "S7",
  // "S8",
  // "S9",
  // "SM1",
  // "SM2",
  // "bill",
  // "coin",
  // "lid",
];

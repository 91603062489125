import * as React from "react";
import { Routes, Navigate, Route, useParams } from "react-router-dom";

import ViewMenu from "common/components/viewmenu/ViewMenu";

import Inventory from "containers/machinedetail/views/inventory/Inventory";
import Health from "containers/machinedetail/views/health/Health";
import Transaction from "containers/machinedetail/views/transaction/Transaction";
import CashEvent from "containers/machinedetail/views/cashEvent";
import MachineIndexTransation from "./views/transaction/IndexTransaction/MachineIndexTransation";
import Details from "containers/machinedetail/views/details/Details";
import Status from "./views/status/Status";
import Debug from "containers/machinedetail/views/debug/Debug";
import Members from "containers/machinedetail/views/members/Members";
import { Maintenance } from "./views/maintenance/Maintenance";

import useIsVendiiUser from "common/hooks/useIsVendiiUser";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";

import * as styles from "./MachineDetail.module.scss";
import {
  GetMachinesStatusByParentIdQuery,
  GetMachineStatusByIdQuery,
  MachineKind,
  MachineStatusEnum,
  GetMachineDetailByIdQuery,
  useGetMachineDetailByIdQuery,
  useGetMachinesStatusByParentIdQuery,
  useGetMachineStatusByIdQuery,
} from "gql/generated";
import { IMachineData } from "machines/interfaces";
import { MachineTransactionGroupByError } from "./views/transaction/groupByError";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { Timeline } from "./views/timeline/Timeline";
import { Snapshots } from "./views/snapshots/Snapshots";

function mapIMachineDataToMachineStatusEnum(
  status: IMachineData["status"]
): MachineStatusEnum {
  switch (status) {
    case "online":
      return MachineStatusEnum.Online;
    case "offline":
      return MachineStatusEnum.Offline;
    case "maintenance":
      return MachineStatusEnum.Maintenance;
    case "out_of_service":
      return MachineStatusEnum.OutOfService;
    default:
      return MachineStatusEnum.Offline;
  }
}

function getSubHeader(
  machine: GetMachineDetailByIdQuery["machine"],
  parentMachine: GetMachineStatusByIdQuery,
  spiralMachines: GetMachinesStatusByParentIdQuery
) {
  if (machine?.kind === MachineKind.SpiralVending) {
    return parentMachine?.machine
      ? [
          {
            status: mapIMachineDataToMachineStatusEnum(
              parentMachine?.machine?.status
            ),
            subTitle: "",
            title: parentMachine?.machine?.id,
            to: `/machines/detail/${parentMachine?.machine.id}`,
          },
        ]
      : [];
  }

  return (
    spiralMachines?.machines?.map((machine) => ({
      status: mapIMachineDataToMachineStatusEnum(machine?.status),
      subTitle: "",
      title: machine?.id,
      to: `/machines/detail/${machine?.id}`,
    })) || []
  );
}

function MachineDetail(): JSX.Element {
  const { t } = useTranslation("machine", {
    keyPrefix: "machine_detail",
  });
  const { machineId } = useParams();

  const { data } = useGetMachineDetailByIdQuery({ machineId });
  const machine = data?.machine;

  const { data: spiralMachines } = useGetMachinesStatusByParentIdQuery(
    {
      parentId: machineId,
    },
    {
      enabled: machine?.kind === MachineKind.BeverageVending,
    }
  );

  const { data: parentMachine } = useGetMachineStatusByIdQuery(
    {
      machineId: machine?.parentId,
    },
    {
      enabled:
        machine?.kind === MachineKind.SpiralVending && !!machine?.parentId,
    }
  );

  const { data: userInfo } = useGetCurrentUser();
  const showDebugTab = useIsVendiiUser() || userInfo.canDebug;

  const tabOptions = [
    {
      to: `/machines/detail/${machineId}/inventory`,
      icon: "Inventory",
      translationKey: "menu_inventory",
    },
    {
      to: `/machines/detail/${machineId}/timeline`,
      icon: "Timeline",
      translationKey: t("label_timeline"),
    },
    {
      to: `/machines/detail/${machineId}/snapshots`,
      icon: "Aperture",
      translationKey: t("label_snapshots"),
    },
    {
      to: `/machines/detail/${machineId}/health`,
      icon: "Health",
      translationKey: "menu_health",
    },
    {
      to: `/machines/detail/${machineId}/status`,
      icon: "StatusEye",
      translationKey: "menu_status",
    },
    {
      to: `/machines/detail/${machineId}/transaction/group-by-none`,
      icon: "CreditCard",
      translationKey: "menu_transaction",
    },
    {
      to: `/machines/detail/${machineId}/cash`,
      icon: "Cash",
      translationKey: "menu_cash",
    },
    {
      to: `/machines/detail/${machineId}/details`,
      icon: "Machine",
      translationKey: "menu_details",
    },
    {
      to: `/machines/detail/${machineId}/maintenance`,
      icon: "Maintenance",
      translationKey: "menu_maintenance",
    },
    {
      to: `/machines/detail/${machineId}/members`,
      icon: "Avatar",
      translationKey: "menu_members",
    },
  ];

  // If user is part of vendii, show the debug tab
  if (showDebugTab) {
    tabOptions.push({
      to: `/machines/detail/${machineId}/debug`,
      icon: "Settings",
      translationKey: "workspace_debug",
    });
  }

  return (
    <div className={styles.MachineDetail}>
      <ViewMenu
        backPath="/machines/monitor"
        header={{
          title: machineId,
          subTitle: (
            <div>
              <span>{machine?.name || null}</span>{" "}
              <Tooltip
                placement="top"
                title={t("label_tooltip_tier", { day: machine?.tier })}
              >
                <span className="px-1.5 py-0 rounded-full bg-primary text-white text-body3 font-bold whitespace-nowrap">
                  {t("label_tier")} {machine?.tier}
                </span>
              </Tooltip>
            </div>
          ),
          status: mapIMachineDataToMachineStatusEnum(machine?.status),
        }}
        subHeader={getSubHeader(machine, parentMachine, spiralMachines)}
        type={
          machine?.kind === MachineKind.SpiralVending
            ? "SpiralMachine"
            : "TaobinMachine"
        }
        options={tabOptions}
      />

      <div className={styles.content}>
        <Routes>
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/snapshots" element={<Snapshots />} />
          <Route path="/health" element={<Health />} />
          <Route path="/status" element={<Status />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/cash" element={<CashEvent />} />
          <Route
            path="/transaction/group-by-none"
            element={<MachineIndexTransation />}
          />
          <Route
            path="/transaction/group-by-error"
            element={<MachineTransactionGroupByError />}
          />
          <Route path="/details" element={<Details />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/members" element={<Members />} />

          <Route path="/debug" element={<Debug />} />
          <Route
            path="*"
            element={
              <Navigate to={`/machines/detail/${machineId}/inventory`} />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default MachineDetail;

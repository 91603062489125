import { RefillZonesListHeader } from "containers/location/components/RefillZonesHeader";
import * as React from "react";
import { RefillZonesListTable } from "./table";
export default function RefillZonesList() {
  return (
    <>
      <RefillZonesListHeader />
      <RefillZonesListTable />
    </>
  );
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { clsx } from "clsx";
import { EnhrichedMachineSnapshot } from "./useMachineSnapshot";

export function DateHeaders({
  machineSnapshot,
}: {
  machineSnapshot: EnhrichedMachineSnapshot | undefined;
}) {
  const { t } = useTranslation("machine", { keyPrefix: "snapshots" });

  const firstEntry = machineSnapshot ? machineSnapshot[0] : undefined;

  let days: Array<moment.Moment> = [];

  if (firstEntry) {
    days = [
      moment(firstEntry.snapshots[6].timestamp),
      moment(firstEntry.snapshots[18].timestamp),
      moment(firstEntry.snapshots[30].timestamp),
    ];
  } else {
    days = [
      moment().subtract(2, "days"),
      moment().subtract(1, "days"),
      moment(),
    ];
  }

  return (
    <div className="flex grow items-end">
      {days.map((day, index) => (
        <div
          key={index}
          className={clsx(
            "w-[480px] first:ml-5 flex items-center justify-center text-body3 font-medium ",
            moment().isSame(day, "day")
              ? "text-on-surface-high"
              : "text-on-surface-medium"
          )}
        >
          {day.format("MMMM D, YYYY")}
          {moment().isSame(day, "day") ? ` (${t("today")})` : ""}
          {moment().subtract(1, "day").isSame(day, "day")
            ? ` (${t("yesterday")})`
            : ""}
        </div>
      ))}
    </div>
  );
}

const times = new Array(37).fill(null).map((_, index) => (index * 2) % 24);

export const timeHeader = (
  <div className="flex">
    {times.map((time, index) => (
      <div
        className={clsx(
          "h-[30px] w-[40px] text-overline tracking-[0.4px] flex items-center justify-center ",
          time >= 6 && time <= 18 ? "text-[#F2994A]" : "text-primary-600"
        )}
        key={index}
      >
        <span className="mt-1">{`${time.toString().padStart(2, "0")}:00`}</span>
      </div>
    ))}
  </div>
);

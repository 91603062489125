import * as React from "react";
import xsvg from "./xsvg.svg";
import {
  Category,
  Snapshot,
  STATUS_TO_CATEGORY
} from "./useMachineSnapshot";

export const getStatusIndicator = ({ snapshot }: { snapshot: Snapshot; }) => {
  if (snapshot.isFuture) {
    return NO_DATA_FUTURE;
  }
  const category = STATUS_TO_CATEGORY[snapshot.status] ?? "NO_DATA";
  return CATEGORY_TO_ICON[category];
};

const NO_DATA_FUTURE = (
  <div className="w-[10px] h-[10px] border-[1px] box-border border-[#F2F2F2] rounded-full bg-white group-hover:border-[#E0E0E0] group-hover:bg-[#F1F1F6]" />
);

const CATEGORY_TO_ICON: Record<Category, JSX.Element> = {
  ERROR: <div className="w-[10px] h-[10px] rounded-full bg-error" />,
  WARN: <div className="w-[10px] h-[10px] rounded-full bg-warning" />,
  DISABLED: (
    <div className="w-[10px] h-[10px] rounded-full bg-on-surface-disabled" />
  ),
  NORMAL: <div className="w-[10px] h-[10px] rounded-full bg-secondary-100" />,
  NO_DATA: <img src={xsvg} className="block" />,
};

import { createColumnHelper } from "components/tableV2";
import { SalesByLocationTableDataRow } from "./SalesByLocationTableMapper";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils/currency";

export function useGenerateSalesByLocationConfig() {
  const { t } = useTranslation("transaction", {
    keyPrefix: "transaction_group_by_location_page.columns"
  });
  const columnHelper = createColumnHelper<SalesByLocationTableDataRow>();
  return [
    columnHelper.accessor("friendlyId", {
      header: () => <>{t("label_friendly_id")}</>,
      cell: (info) => <>{info.getValue()}</>,
      minSize: 90
    }),

    columnHelper.accessor("locationName", {
      header: () => <>{t("label_location_name")}</>,
      cell: (info) => <>{info.getValue()}</>,
      minSize: 90,
      meta: {
        autoColumnWidth: true
      }
    }),

    columnHelper.accessor("totalSales", { 
      header: () => <>{t("label_total_sales")}</>,
      cell: (info) => <>{formatCurrency({input: info.getValue()})}</>,
      enableSorting: true,
      minSize: 90
    }),

    columnHelper.accessor("salesCount", {
      header: () => <>{t("label_total_unit_of_sales")}</>,
      cell: (info) => <>{info.getValue()}</>,
      enableSorting: true,
      minSize: 90
    }),

    columnHelper.accessor("totalPromotions", {
      header: () => <>{t("label_total_promotion")}</>,
      cell: (info) => <>{formatCurrency({input: info.getValue()})}</>,
      enableSorting: true,
      minSize: 90
    }),
  ];
}

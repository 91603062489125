import * as React from "react";
import ViewNav from "common/components/viewnav/ViewNav";

export function LocationNavigation () {
  return (
        <ViewNav
          options={[
            {
              icon: "Location",
              to: "/location/list"
            },
            {
              icon: "Service",
              to: "/location/service-zones"
            },
            {
              icon: "Truck",
              to: "/location/refill-zones"
            }
          ]}
        />
  );   
}

import * as React from 'react';

// Hooks
import { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// Custom hooks
import useQuerySync from 'common/hooks/useQuerySync';

// Configs & Default settings
import { filterOptionsForGroupBy } from 'transactions/constants/filterOptionsForGroupBy';
import salesTableColumnsConfig from '../../transactions/constants/salesTableColumnsConfig';

// Component imports
import Table, { IColumn } from 'common/components/table/Table';
import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';
import TextButton from 'common/components/textbutton/TextButton';
import { DateRangePicker } from "components/DateRangePicker";
import Select from 'common/components/select/Select';
import { LinkCell } from 'common/components/table/cells/Cells';
import { Paginator } from 'transactions/components/Paginator';
import {
  IAppliedFilter,
  PureFilterBar,
} from 'common/components/filterbar/PureFilterBar';
import { IStringFillBackgroundCellRenderProp } from 'common/components/table/cells/StringFillBackgroundCell/StringFillBackgroundCell';

// Service imports
import { getAllTransactions, getAllTransactionsCSV } from 'machines/service';

// Utils
import * as moment from 'moment';

// Custom utils
import insertTransactionHeader from 'transactions/utils/insertTransactionHeader';
import {
  formatMomentDateFullYear,
  formatMomentDateShortDayAndMonth,
} from 'common/utils/format';
import { downloadCSVFromJSON } from 'utils/csv';
import {
  UNIXTimeStampTodayInMS,
  UNIXTimeStampXDaysAgoInMS,
} from 'common/utils/momentUtils';
import { getDefaultQueryParamsForGroupBy } from 'transactions/utils/getDefaultQueryParamsForGroupBy';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';
import {
  useIsAdmin,
  useIsAdminContract,
  useIsOperator,
  useIsRouteman,
  useIsSuperViewer,
  useIsTechnician,
  useIsViewer,
} from 'utils/user-role';

// Style imports
import * as styles from './Transactions.module.scss';
import { adjustCurrencyValue } from 'utils/currency';
import { groupByOptions } from './common/groupByOptions';

function Transactions(): JSX.Element {
  const cannotViewContent = useIsViewer() || useIsRouteman() || useIsTechnician();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }

  const canUploadDonwload = useIsSuperViewer() || useIsOperator() || useIsAdmin() || useIsAdminContract();

  const navigate = useNavigate();

  const { queryObject, setQueryObject, queryString } = useQuerySync(
    getDefaultQueryParamsForGroupBy('productName')
  );
  // for group by that does not support ahmet api
  const [localFilter, setLocalfilter] = useState(null);

  const [data, setData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMounted = useRef<boolean>(true);
  const queryStamp = useRef<string | null>(null);

  useLayoutEffect(() => {
    fetchFromSales();
  }, [queryString]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function fetchFromSales() {
    setIsLoading(true);
    setData([]);
    queryStamp.current = queryString;

    let queryParams = { ...queryObject };
    // Make changes to the query params before making the request
    // Used for dailyMachineSales and weeklyMachineSales to prevent showing time stamp in URL
    switch (queryParams.groupBy) {
      // case 'allTransactionNone':
      case 'none':
      case 'machineId':
      case 'productName':
      case 'status':
      case 'telephoneNumber':
      case 'locationId':
      case 'locationType':
        break;
      case 'dailyMachineSales':
        queryParams = {
          ...queryParams,
          from: UNIXTimeStampXDaysAgoInMS(13, { timeOfDay: 'dayStart' }),
          to: UNIXTimeStampTodayInMS({ timeOfDay: 'dayEnd' }),
        };
        break;
      case 'weeklyMachineSales': {
        queryParams = {
          ...queryParams,
          from: UNIXTimeStampXDaysAgoInMS(1 + 7 * 10, {
            timeOfDay: 'dayStart',
          }),
        };
        break;
      }
    }

    // Transform the incoming data for the table
    try {
      const APIResponse = await getAllTransactions(queryParams);
      const { results, meta } = transformData(APIResponse, queryParams.groupBy);

      if (isMounted.current && queryStamp.current === queryString) {
        setData(results);
        setMetaData(meta === undefined ? null : meta);
        setIsLoading(false);
      }
    } catch (err) {
      if (isMounted.current && queryStamp.current === queryString) {
        setData(null);
        setMetaData(null);
        setIsLoading(false);
      }
    }
  }

  function handleRefresh(): void {
    fetchFromSales();
  }

  // Change query params when groupby select
  function onGroupBySelect(selectedGroupBy) {
    selectedGroupBy === 'allTransactionNone' &&
      navigate('/transactions/group-by-none');

    selectedGroupBy === 'groupByError' &&
      navigate('/transactions/group-by-error');

    selectedGroupBy === 'locationId' &&
      navigate('/transactions/group-by-location');

    if (selectedGroupBy === queryObject.groupBy) {
      return;
    }

    setData([]);
    setLocalfilter(null);

    let defaultQueryParams = getDefaultQueryParamsForGroupBy(selectedGroupBy);

    // Overwrite from and to parameters for group bys from the default config if there's one in the top
    switch (selectedGroupBy) {
      case 'machineId':
      case 'productName':
      case 'status':
      case 'locationId':
      case 'locationType':
      case 'codeId':
      case 'codeType':
      case 'telephoneNumber':
        if ('from' in queryObject && 'to' in queryObject) {
          defaultQueryParams = {
            ...defaultQueryParams,
            from: queryObject.from,
            to: queryObject.to,
          };
        }
        break;
      case 'dailyMachineSales':
      case 'weeklyMachineSales':
        break;
    }
    setQueryObject(defaultQueryParams); // Change the URL parameters
  }

  // Only valid for group bys that have a date picker (Ahmet's API)
  function onDatePickerBlur({ startDate, endDate }) {
    if (startDate >= endDate) {
      return;
    }

    const queryParams = {
      ...getDefaultQueryParamsForGroupBy(queryObject.groupBy),
      from: startDate,
      to: endDate,
    };

    // Keep filter options when date picker changes (Ahmet's API)
    setQueryObject((queryObject) => {
      if ('filter' in queryObject && 'filterBy' in queryObject) {
        return {
          ...queryParams,
          filterBy: queryObject.filterBy,
          filter: queryObject.filter,
        };
      } else {
        return queryParams;
      }
    });
  }

  // Used to work with PureFilterBar & Ahmet's API
  function onFilterSelect(appliedFilter: IAppliedFilter | null) {
    if (queryObject.groupBy === 'dailyMachineSales') {
      setLocalfilter(appliedFilter);
      return;
    }
    if (appliedFilter === null) {
      setQueryObject((queryObject) => {
        const { filter, filterBy, ...rest } = queryObject;
        return rest;
      });
    } else {
      setQueryObject((queryObject) => ({
        ...queryObject,
        filter: appliedFilter.value,
        filterBy: appliedFilter.field,
      }));
    }
  }

  // Download CSV from API
  const CSVDownloading = useRef(false);

  // Used for hitting Ahmet's ?download=true endpoint
  async function handleDownload() {
    // If downloading, do not attempt to download again
    if (CSVDownloading.current === true) {
      return;
    }
    CSVDownloading.current = true;

    const rawCSV = await getAllTransactionsCSV(queryObject);

    const CSVFile = new File(['\ufeff' + rawCSV], 'transactions.csv', {
      type: 'text/csv',
    });

    // Create link to file and simulate click
    const anchorElement = document.createElement('a');
    anchorElement.href = URL.createObjectURL(CSVFile);
    anchorElement.download = `transactions-${
      queryObject.groupBy
    }-${formatMomentDateFullYear(
      moment(Number(queryObject.from))
    )}-${formatMomentDateFullYear(moment(Number(queryObject.to)))}.csv`;
    anchorElement.click();

    URL.revokeObjectURL(anchorElement.href);

    CSVDownloading.current = false;
  }

  // Used for building CSV from data returned Tom's API
  function handleDownloadCSVForDailySales() {
    downloadCSVFromJSON(
      data.map((row) => {
        function computeDateHeaderXDaysAgo(X: number) {
          const XDaysAgo = moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .subtract(X, 'days');
          return formatMomentDateFullYear(XDaysAgo);
        }

        return {
          id: row.machineId,
          name: row.name,
          org: row.orgName,
          expense: row.expense,
          [computeDateHeaderXDaysAgo(13)]: adjustCurrencyValue(row[0] ?? 0),
          [computeDateHeaderXDaysAgo(12)]: adjustCurrencyValue(row[1] ?? 0),
          [computeDateHeaderXDaysAgo(11)]: adjustCurrencyValue(row[2] ?? 0),
          [computeDateHeaderXDaysAgo(10)]: adjustCurrencyValue(row[3] ?? 0),
          [computeDateHeaderXDaysAgo(9)]: adjustCurrencyValue(row[4] ?? 0),
          [computeDateHeaderXDaysAgo(8)]: adjustCurrencyValue(row[5] ?? 0),
          [computeDateHeaderXDaysAgo(7)]: adjustCurrencyValue(row[6] ?? 0),
          [computeDateHeaderXDaysAgo(6)]: adjustCurrencyValue(row[7] ?? 0),
          [computeDateHeaderXDaysAgo(5)]: adjustCurrencyValue(row[8] ?? 0),
          [computeDateHeaderXDaysAgo(4)]: adjustCurrencyValue(row[9] ?? 0),
          [computeDateHeaderXDaysAgo(3)]: adjustCurrencyValue(row[10] ?? 0),
          [computeDateHeaderXDaysAgo(2)]: adjustCurrencyValue(row[11] ?? 0),
          [computeDateHeaderXDaysAgo(1)]: adjustCurrencyValue(row[12] ?? 0),
          expected: adjustCurrencyValue(row.expected ?? 0),
          [computeDateHeaderXDaysAgo(0)]: adjustCurrencyValue(row[13] ?? 0),
        };
      }),
      'Transaction daily sales'
    );
  }

  // Used for building CSV from data returned Tom's API
  function handleDownloadCSVForWeeklyMachineSales() {
    function computeDateHeaderXDaysAgo(X: number) {
      const XDaysAgo = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .subtract(X, 'days');
      return formatMomentDateFullYear(XDaysAgo);
    }

    downloadCSVFromJSON(
      data.map((row) => {
        return {
          id: row.machineId,
          name: row.name,
          org: row.organisation,
          expense: row.expense,
          [computeDateHeaderXDaysAgo(1 + 8 * 7)]: adjustCurrencyValue(row[0] ?? 0),
          [computeDateHeaderXDaysAgo(1 + 7 * 7)]: adjustCurrencyValue(row[1] ?? 0),
          [computeDateHeaderXDaysAgo(1 + 6 * 7)]: adjustCurrencyValue(row[2] ?? 0),
          [computeDateHeaderXDaysAgo(1 + 5 * 7)]: adjustCurrencyValue(row[3] ?? 0),
          [computeDateHeaderXDaysAgo(1 + 4 * 7)]: adjustCurrencyValue(row[4] ?? 0),
          [computeDateHeaderXDaysAgo(1 + 3 * 7)]: adjustCurrencyValue(row[5] ?? 0),
          [computeDateHeaderXDaysAgo(1 + 2 * 7)]: adjustCurrencyValue(row[6] ?? 0),
          [computeDateHeaderXDaysAgo(1 + 1 * 7)]: adjustCurrencyValue(row[7] ?? 0),
          trendPercentage: row.trend.percentage,
          trendValue: row.trend.value,
        };
      }),
      'Transaction weeekly sales'
    );
  }

  function handleDownloadCSVForDailySalesGroupByLocationId() {
    downloadCSVFromJSON(
      data
        .map((row) => {
          return {
            friendlyId: row.friendlyId ?? 'UNKNOWN',
            locationName: row.locationName,
            totalSale: adjustCurrencyValue(row.totalServiceSale ?? 0),
            totalSaleCount: row.totalServiceSaleCount,
            totalCampaignDiscount: adjustCurrencyValue(row.totalCampaignDiscount ?? 0),
          };
        })
        .sort((a, b) => a.friendlyId.localeCompare(b.friendlyId)),
      `Transaction daily sales group by location ${formatMomentDateFullYear(
        moment(Number(queryObject.from))
      )}-${formatMomentDateFullYear(moment(Number(queryObject.to)))}`
    );
  }

  // Get the current column config based on the current group by
  let currentColumnsConfig = [
    ...salesTableColumnsConfig[queryObject.groupBy],
  ] as IColumn[];

  // Find machineId column and keep from and to query string parameters same when navigating to /machines/detail/:machineId/transaction
  const machineIdColumnConfigIndex = currentColumnsConfig.findIndex(
    (columnConfig) => columnConfig.dataKey === 'machineId'
  );

  // Daily machine sales will move to single machine transactions page with from and to injected
  // Other page with machine Id will take from and to from query
  if (machineIdColumnConfigIndex !== -1) {
    if (queryObject.groupBy === 'dailyMachineSales') {
      const queryString = new URLSearchParams({
        ...getDefaultQueryParamsForGroupBy('none'),
        from: UNIXTimeStampXDaysAgoInMS(13, { timeOfDay: 'dayStart' }),
        to: UNIXTimeStampTodayInMS({ timeOfDay: 'dayEnd' }),
      } as any).toString();
      currentColumnsConfig[machineIdColumnConfigIndex] = {
        ...currentColumnsConfig[machineIdColumnConfigIndex],
        cellRenderer: LinkCell(
          `/machines/detail/{{ data }}/transaction?${queryString}`
        ),
      };
    } else if (queryObject.groupBy === 'weeklyMachineSales') {
      const queryString = new URLSearchParams({
        ...getDefaultQueryParamsForGroupBy('none'),
        from: UNIXTimeStampXDaysAgoInMS(1 + 8 * 7, { timeOfDay: 'dayStart' }),
        to: UNIXTimeStampTodayInMS({ timeOfDay: 'dayEnd' }),
      } as any).toString();
      currentColumnsConfig[machineIdColumnConfigIndex] = {
        ...currentColumnsConfig[machineIdColumnConfigIndex],
        cellRenderer: LinkCell(
          `/machines/detail/{{ data }}/transaction?${queryString}`
        ),
      };
    } else {
      const { from, to } = queryObject;
      const queryString = new URLSearchParams({
        ...getDefaultQueryParamsForGroupBy('none'),
        from,
        to,
      } as any).toString();
      currentColumnsConfig[machineIdColumnConfigIndex] = {
        ...currentColumnsConfig[machineIdColumnConfigIndex],
        cellRenderer: LinkCell(
          `/machines/detail/{{ data }}/transaction?${queryString}`
        ),
      };
    }
  }

  // Remove headerLabel and use headerCellRenderer with 'TransactionHeader.tsx'
  // {headerLabel: label_machine_name, ...} -> {headerCellRenderer: () => <TransactionHeader label={label_machine_name} .../>, ...}
  // Applies to all configs object in currentColumnsConfig array
  if (queryObject.groupBy === 'dailyMachineSales') {
    // Loop through each config, ignore first 3 config
    // Date key will be 0 1 2 3 4 5 .... 0 means 13 days ago [thirteenDaysAgo: number, ... ,]
    // Dynamically generate the headers for groupByDailyMachineSales
    currentColumnsConfig = currentColumnsConfig.map((elem) => {
      if (elem.dataKey === '13') return { ...elem, headerLabel: 'label_today' };
      if (isNaN(Number(elem.dataKey))) return elem;
      const daysToBeSubstracted = 13 - Number(elem.dataKey);
      const momentDay = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .subtract(daysToBeSubstracted, 'days');
      const dateString = formatMomentDateShortDayAndMonth(momentDay);
      return { ...elem, headerLabel: dateString };
    });
  } else if (queryObject.groupBy === 'weeklyMachineSales') {
    // Loop through each config, ignore first 3 config
    // Date key will be 0 1 2 3 4 5 .... 0 means 13 days ago [thirteenDaysAgo: number, ... ,]
    // Dynamically generate the headers for groupByDailyMachineSales
    currentColumnsConfig = currentColumnsConfig.map((elem, index) => {
      // index = 0 -> machineId
      // index = 1 -> name
      // index = 2 -> Org
      // index = 3 -> Expense
      // index = 12 -> Trend
      if (
        index === 0 ||
        index === 1 ||
        index === 2 ||
        index === 3 ||
        index === 12
      )
        return elem;
      // 0 means end of first week so days to subtract = 1 + 9 * 7 (would probably make more sense if start at 1)
      const daysToBeSubstracted = 1 + (8 - Number(elem.dataKey)) * 7; // dateKey will be initiall by 0,1,2,3,4,5,6,7,8,9
      const momentDay = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .subtract(daysToBeSubstracted, 'days');
      const dateString = formatMomentDateShortDayAndMonth(momentDay);
      return { ...elem, headerLabel: dateString };
    });
  } else if (queryObject.groupBy !== 'locationId') {
    // Use custom header for the others - Ahmet's API to change sort
    currentColumnsConfig = insertTransactionHeader(
      currentColumnsConfig,
      queryObject,
      setQueryObject
    );
  }

  return (
    <div className={styles.Transactions}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography
              type="headline-5"
              translationKey="workspace_transactions"
            />
            <div className={styles.refresh} onClick={handleRefresh}>
              <Icon name="Refresh" color="primary" />
            </div>
          </div>
          {canUploadDonwload && !!data?.length && (
            <TextButton
              translationKey="action_download"
              onClick={() => {
                switch (queryObject.groupBy) {
                  case 'none':
                  case 'machineId':
                  case 'productName':
                  case 'status':
                  case 'telephoneNumber':
                  case 'codeId':
                  case 'codeType':
                  case 'locationType':
                    handleDownload();
                    break;
                  case 'locationId':
                    handleDownloadCSVForDailySalesGroupByLocationId();
                    break;
                  case 'dailyMachineSales':
                    handleDownloadCSVForDailySales();
                    break;
                  case 'weeklyMachineSales': {
                    handleDownloadCSVForWeeklyMachineSales();
                    break;
                  }
                }
              }}
              icon="Download"
              className={styles.action}
            />
          )}
        </div>
        <div className={styles.right}>
          <Select
            className={styles.groupby}
            label="label_group_by"
            value={queryObject.groupBy}
            options={groupByOptions}
            onSelect={onGroupBySelect}
          />
          {queryObject.groupBy === 'dailyMachineSales' ||
          queryObject.groupBy === 'weeklyMachineSales' ? (
            <div className={styles.disableCalenderContainer}>
              <Typography
                type="subtitle-1"
                translationKey="label_date_selector_disable"
                className={styles.disableCalenderString}
              />
            </div>
          ) : (
            <DateRangePicker
              defaultStartDate={Number(queryObject.from)}
              defaultEndDate={Number(queryObject.to)}
              className={styles.action}
              onBlur={onDatePickerBlur}
              key={queryString}
            />
          )}
        </div>
      </div>
        <PureFilterBar
        onChange={onFilterSelect}
        value={
          // only this is not support API /sales
          queryObject.groupBy === 'dailyMachineSales'
            ? localFilter
            : queryObject.filterBy === undefined
            ? null
            : {
                field: queryObject.filterBy,
                queryVerb: 'contains',
                value: queryObject.filter,
              }
        }
        filterOptions={filterOptionsForGroupBy[queryObject.groupBy]}
        key={queryString}
      />
      <div className={styles.TableContainer}>
        <Table
          infiniteScroll={false}
          className={styles.table}
          data={
            queryObject.groupBy === 'dailyMachineSales' && localFilter !== null
              ? data.filter((machine) =>
                  machine.orgName
                    .toLowerCase()
                    .includes(localFilter.value.toLowerCase())
                )
              : data
          }
          loading={isLoading}
          loadingRows={20}
          columns={currentColumnsConfig}
          key={queryString}
        />
      </div>
      {/* Check if both meta and currentPage undefined (Meta current page undefined for machine list */}
      {metaData?.currentPage !== undefined && (
        <Paginator metaData={metaData} queryObject={queryObject} />
      )}
    </div>
  );
}

export default Transactions;

function transformDailyMachineSalesData(results) {
  const dailyMachineSalesData = [];
  const data = results.data;
  for (const machineId in data) {
    dailyMachineSalesData.push({
      machineId,
      name: data[machineId].name,
      orgName: data[machineId].orgName,
      expense: data[machineId].expense ?? 0,
      expected: data[machineId].historicalSale ?? 0,
      ...data[machineId].sales,
      diff: {
        table: computeDiff(
          data[machineId].sales[13],
          data[machineId].historicalSale ?? 0
        ),
        value:
          data[machineId].sales[13] - (data[machineId].historicalSale ?? 0),
      },
    });
  }
  return dailyMachineSalesData;
}

function computeDiff(
  today: number,
  expected: number
): IStringFillBackgroundCellRenderProp {
  const diff = today - expected;
  let textColor: 'success' | 'successOverlay' | 'warning' | 'error';
  if (diff >= 0) {
    textColor = 'success';
  } else if (diff >= -100) {
    textColor = 'successOverlay';
  } else if (diff >= -800) {
    textColor = 'warning';
  } else {
    textColor = 'error';
  }
  return {
    backgroundColor: undefined,
    textColor: textColor,
    translationKey: diff >= 0 ? `+${diff}` : `${diff}`,
  };
}

interface IWeeklyMachineSales {
  machineId: string;
  name: string;
  orgName: string;
  rent: string;
  sales: {
    saleCount: number;
    saleSum: number;
  }[];
  trend: {
    value: number;
    percent: number;
  };
}

function transformWeeklyMachineSalesData(APIResponse: IWeeklyMachineSales[]) {
  return APIResponse.map((machine) => ({
    machineId: machine.machineId,
    name: machine.name,
    organisation: machine.orgName,
    expense: machine.rent ?? 0,
    ...machine.sales.map((weeklySaleInfo) => weeklySaleInfo.saleSum).slice(2),
    trend: {
      sparkLineData: machine.sales.map((weeklySalesInfo, index) => [
        index,
        weeklySalesInfo.saleSum,
      ]),
      value: machine.trend.value,
      percentage: machine.trend.percent,
      color: computeTrendColor(machine.trend.percent),
    },
  }));
}

function computeTrendColor(percent): 'success' | 'warning' | 'error' {
  if (percent >= 0) {
    return 'success';
  } else if (percent >= -0.05) {
    return 'warning';
  } else {
    return 'error';
  }
}

function transformData(APIResponse, groupBy) {
  switch (groupBy) {
    case 'none': // Meta + results
    case 'machineId': // Meta without pagination
    case 'productName': // Meta + results
    case 'status': // No meta only results
    case 'telephoneNumber': // Meta + results
    case 'codeId':
    case 'codeType':
    case 'locationId':
    case 'locationType':
      return APIResponse;
    case 'dailyMachineSales':
      return { results: transformDailyMachineSalesData(APIResponse.results) }; // Only results field
    case 'weeklyMachineSales':
      return { results: transformWeeklyMachineSalesData(APIResponse) }; // No results and meta field
  }
}

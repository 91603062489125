import * as moment from "moment";
import {
  MachineSnapshotStatus, MachineSnapshotDocument,
  MachineSnapshotQueryVariables,
  MachineSnapshotQuery
} from "gql/generated";
import { fetcher } from "gql/fetcher";
import { useQuery, UseQueryOptions } from "react-query";

export type EnhrichedMachineSnapshot = Array<{
  type: string;
  snapshots: Array<Snapshot>;
}>;

export type Snapshot = {
  status: MachineSnapshotStatus;
  timestamp: string;
  isFuture: boolean;
  consecutiveIndexes: number;
};

export function useMachineSnapshot(
  variables: MachineSnapshotQueryVariables,
  options?: UseQueryOptions<
    EnhrichedMachineSnapshot, unknown, EnhrichedMachineSnapshot
  >) {
  return useQuery<EnhrichedMachineSnapshot, unknown, EnhrichedMachineSnapshot>(
    ["MachineSnapshot", variables],
    () => getSnapshot(variables),
    options
  );
}

async function getSnapshot(variables: MachineSnapshotQueryVariables) {
  return await fetcher<MachineSnapshotQuery, MachineSnapshotQueryVariables>(
    MachineSnapshotDocument,
    variables
  )().then(enrichSnapshotData);
}

function enrichSnapshotData(
  data: MachineSnapshotQuery
): EnhrichedMachineSnapshot {
  const currentDate = moment();
  const firstEntry = data.machineSnapshot[0];
  const isFutureArray: boolean[] = firstEntry.snapshots.map((snapshot) => moment(snapshot.timestamp).isAfter(currentDate)
  );
  // 37th Array element always in the future
  isFutureArray.push(true);

  for (let i = 0; i < data.machineSnapshot.length; i++) {
    const type = data.machineSnapshot[i];
    type.snapshots.push({
      status: MachineSnapshotStatus.NoData,
      timestamp: "",
    });

    const castedSnapshot = type.snapshots as Snapshot[];

    for (let j = 0; j < 37; j++) {
      castedSnapshot[j].isFuture = isFutureArray[j];
    }

    let previousCategory = null;
    let startingIndex = 0;
    for (let i = 0; i < castedSnapshot.length; i++) {
      const currentElement = castedSnapshot[i];
      const currentCategory = (currentElement.isFuture
        ? "FUTURE"
        : STATUS_TO_CATEGORY[currentElement.status]) ?? "UNKNOWN";

      // Handle first element
      if (previousCategory === null) {
        previousCategory = currentCategory;
        continue;
      }

      if (previousCategory !== currentCategory) {
        for (let j = startingIndex; j < i; j++) {
          castedSnapshot[j].consecutiveIndexes = i - startingIndex;
        }
        startingIndex = i;
        previousCategory = currentCategory;
        continue;
      }
    }

    for (let j = startingIndex; j < 37; j++) {
      castedSnapshot[j].consecutiveIndexes = 37 - startingIndex;
    }
  }

  return data.machineSnapshot as EnhrichedMachineSnapshot;
}

export const STATUS_TO_CATEGORY = {
  [MachineSnapshotStatus.Disable]: "ERROR",
  [MachineSnapshotStatus.DisableLow]: "ERROR",
  [MachineSnapshotStatus.Error]: "ERROR",

  [MachineSnapshotStatus.Abnormal]: "WARN",
  [MachineSnapshotStatus.Low]: "WARN",

  [MachineSnapshotStatus.Off]: "DISABLED",

  [MachineSnapshotStatus.Normal]: "NORMAL",

  [MachineSnapshotStatus.NoData]: "NO_DATA",
} as const;

export type Category = (typeof STATUS_TO_CATEGORY)[keyof typeof STATUS_TO_CATEGORY];

export const SNAPSHOT_TYPE_TO_TRANSLATION = {
  // Inventory
  coin: "coin",
  plastic_cup: "plasticCup",
  paper_cup: "paperCup",
  espresso_cup: "espressoCup",
  coffee_material: "coffeeBeans",
  bill: "bill",
  lid: "lid",
  // Module
  coffee: "coffeeError",
  ice: "iceError",
  machine_error: "machineUnsellable",
  out_of_service: "outOfService",
  offline: "offline",
};
